import { i18n } from '@/i18n';

export const PAYMENT_STATUS = {
    authorized: 'AUTHORIZED',
    paid: 'PAID',
    declined: 'DECLINED',
};

export const DEFAULT_TPP_IFRAME_STYLES = {
    appearance: 'custom',
    inputStyle: {
        mode: 'simple',
        background: 'white',
        borderColor: '#d8d8d8',
        borderWidth: '1px',
        fontWeight: '500',
        focus: {
            borderColor: '#8f69cc',
        },
        placeholder: {
            background: 'white',
            color: '#a5a5a5',
        },
    },
    paymentMethodStyle: {
        selectedBackgroundColor: '#fff',
        selectedBorderColor: '#313a56',
        selectedTextColor: '#8f69cc',
        hoverBackgroundColor: '#fff',
        hoverTextColor: '#313a56',
        hoverBorderColor: '#8f69cc',
        borderColor: '#d8d8d8',
        backgroundColor: '#fff',
        textColor: '#313a56',
    },
    submitButtonStyle: {
        backgroundColor: '#8f69cc',
        textColor: '#f7f3ff',
        hoverBackgroundColor: '#8662bf',
        hoverTextColor: '#f7f3ff',
    },
};

export const PAYMENT_METHODS_MAP = {
    cc: 'credit card',
    debit: 'bank account',
};

export const ACCOUNTING_ERROR_CODES_MAP = {
    13310: 'Resident account must have at least one charge',
};

export const PERIODS_FILTER_OPTIONS = [
    {
        key: 'all',
        value: i18n.global.t('accounting.periods_filter_options.all'),
    },
    {
        key: 'currentMonth',
        value: i18n.global.t('accounting.periods_filter_options.this_month'),
    },
    {
        key: 'lastMonth',
        value: i18n.global.t('accounting.periods_filter_options.last_month'),
    },
    {
        key: 'lastSixMonth',
        value: i18n.global.t('accounting.periods_filter_options.last_six_month'),
    },
    {
        key: 'lastYear',
        value: i18n.global.t('accounting.periods_filter_options.last_year'),
    },
];

export const MOBILE_DEVICE_WIDTH = 1024;
