<template>
    <div class="max-h-64 overflow-auto px-2">
        <table class="table-payments w-full h-full border-collapse bg-transparent">
            <colgroup>
                <slot name="colgroup" />
            </colgroup>
            <thead>
                <tr class="sticky top-0 bg-white">
                    <slot name="headers" />
                </tr>
            </thead>
            <tbody>
                <slot name="content" />
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'TableDesktop',
};
</script>
