<template>
    <div :class="{ 'bg-gray-200 py-3 px-4 rounded-lg': isActive }">
        <div
            class="flex justify-between font-ffdin text-md2 leading-6"
            :class="{ 'text-purple-600 font-bold': isActive }"
            data-test="item"
            @click="toggle"
        >
            <slot name="main" />
        </div>
        <div
            v-if="isActive"
            class="flex justify-between text-sm"
        >
            <slot name="additional" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableMobileItem',

    data() {
        return {
            isActive: false,
        };
    },

    methods: {
        toggle() {
            this.isActive = !this.isActive;
        },
    },
};
</script>
