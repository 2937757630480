export const paymentTypes = {
    full: 'full',
    other: 'other',
};

export const paymentMethods = {
    card: 'credit card',
    bank: 'bank account',
};

export const defaultMeta = {
    take: 100,
    skip: 0,
    sortDirection: 'DESC',
};

export const monthsPeriod = 3;

export const depositsStatuses = ['COMPLETE', 'AUTHORIZED', 1, 3];

export const typeUsers = {
    applicant: 'Applicant',
    resident: 'Resident',
    occupant: 'Occupant',
};
