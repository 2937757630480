<template>
    <div>
        <div
            v-if="loading"
            class="text-center mt-3"
        >
            {{ $t('app.content_loading') }}
        </div>
        <div v-else>
            <div
                v-for="(period, index) in formattedPeriods"
                :key="index"
            >
                <TableAccordionItem
                    :period="getPeriodDate(period)"
                    :balance="currencyFormatter(period.balance)"
                    :is-active="period.active"
                >
                    <div class="block lg:hidden">
                        <div
                            v-for="(item, index) in period.items"
                            :key="index"
                            class="mt-3"
                        >
                            <TableMobileItem>
                                <template #main>
                                    <p>{{ capitalize(item.name) }}</p>
                                    <p>{{ currencyFormatter(item.paidAmount || item.chargeAmount) }}</p>
                                </template>
                                <template #additional>
                                    <p>#{{ item.refNumber }}</p>
                                    <p>{{ dateFormatter(item.postDate) }}</p>
                                </template>
                            </TableMobileItem>
                        </div>
                    </div>

                    <TableDesktop class="hidden lg:block">
                        <template #colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="15%" />
                            <col width="25%" />
                            <col width="10%" />
                        </template>
                        <template #headers>
                            <th>{{ $t('payments.list_columns.item_name') }}</th>
                            <th>{{ $t('payments.list_columns.reference_no') }}</th>
                            <th>{{ $t('payments.list_columns.date_posted') }}</th>
                            <th>{{ $t('payments.list_columns.date_due') }}</th>
                            <th>{{ $t('payments.list_columns.amount') }}</th>
                        </template>
                        <template #content>
                            <tr
                                v-for="(item, index) in period.items"
                                :key="index"
                                :class="{ 'font-bold': item.name === 'payment' }"
                            >
                                <td data-test="name">{{ capitalize(item.name) }}</td>
                                <td data-test="refNumber">{{ item.refNumber }}</td>
                                <td data-test="postDate">{{ dateFormatter(item.postDate) }}</td>
                                <td data-test="due">{{ dateFormatter(item.due) }}</td>
                                <td data-test="amount">{{ currencyFormatter(item.paidAmount || item.chargeAmount) }}</td>
                            </tr>
                        </template>
                    </TableDesktop>
                </TableAccordionItem>
            </div>
        </div>
    </div>
</template>

<script>
import TableMobileItem from '@/components/payments/components/TableMobileItem';
import TableDesktop from '@/components/payments/components/TableDesktop';
import TableAccordionItem from '@/components/payments/components/TableAccordionItem';
import AuthMixin from '@/mixins/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import PayerMixin from '@/mixins/PayerMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { dateFormatter, getPeriodDate } from '@/utils/Date';
import { currencyFormatter } from '@/utils/helpers';
import { getAccountingErrorMessage } from '@/utils/accounting';
import { capitalize } from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
    name: 'AccountSummary',

    components: {
        TableAccordionItem,
        TableMobileItem,
        TableDesktop,
    },

    mixins: [AuthMixin, NotifyMixin, PayerMixin, AccountingQueryManager],

    props: {
        /**
         * Avaliable totals for certain period
         * with period/balance information
         */
        totals: {
            type: Array,
            required: true,
        },

        /**
         * Selected filter of a period to get
         * accounts receivable data
         */
        selectedPeriod: {
            type: Object,
            required: true,
        },

        /**
         * If the selected option is 'all'
         * then load more balances
         */
        loadMore: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
            periods: [],
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
            chargeTotals: 'accounting/chargeTotals',
        }),
    },

    watch: {
        'currentProfile.occupancyId'(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.getOccupancy();
                this.getReceivableBalances();
            }
        },

        selectedPeriod(val) {
            if (val) {
                this.getReceivableBalances();
            }
        },

        loadMore(val) {
            if (val) {
                this.getReceivableBalances();
            }
        },
    },

    async created() {
        if (this.currentProfile?.occupancyId) {
            await this.getOccupancy();
        }
    },

    methods: {
        dateFormatter,
        getPeriodDate,
        capitalize,
        currencyFormatter,

        async getReceivableBalances() {
            this.loading = true;
            const { startDate, endDate } = this.selectedPeriod;
            try {
                this.periods = await this.queryHandler('getReceivableBalances', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                    startDate,
                    endDate,
                });
            } catch (error) {
                this.notifyError(getAccountingErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
