import { i18n } from '@/i18n';
import { ACCOUNTING_ERROR_CODES_MAP } from '@/utils/constants/accounting';
import { startAndEndOfMonth, getYear, getMonthIndex, dateCalculator } from '@/utils/Date';
import store from '@/store';

export const getAccountingErrorMessage = response => {
    if (response) {
        if (response?.data && response.data?.errors && response.data.errors.length > 0) {
            const accountingError = response.data.errors[0];
            const predefinedMessage = ACCOUNTING_ERROR_CODES_MAP[accountingError.errorCode];
            return predefinedMessage ?? accountingError.message;
        }
        return response.message;
    }
    return i18n.global.t('accounting.error_messages.default');
};

const splitIntoOneYearPeriods = (startDate, endDate) => {
    const oneYearPeriods = [];
    let currentStartDate = new Date(startDate);
    const finalEndDate = new Date(endDate);

    while (currentStartDate < finalEndDate) {
        let nextEndDate = new Date(currentStartDate);
        nextEndDate.setFullYear(nextEndDate.getFullYear() + 1);

        if (nextEndDate > finalEndDate) {
            nextEndDate = finalEndDate;
        }

        oneYearPeriods.push({
            startDate: currentStartDate.toISOString(),
            endDate: nextEndDate.toISOString(),
        });

        currentStartDate = new Date(nextEndDate);
        currentStartDate.setDate(currentStartDate.getDate() + 1);
    }

    return oneYearPeriods;
};

export const periodsMap = (periodKey, phaseStartDate) => {
    const startOfCurrentMonth = startAndEndOfMonth({
        year: getYear(new Date()),
        monthIndex: getMonthIndex(new Date()),
    }).startDate;
    const endOfCurrentMonth = startAndEndOfMonth({
        year: getYear(new Date()),
        monthIndex: getMonthIndex(new Date()),
        keepLocalTime: true,
    }).endDate;
    const startDateOfAllPeriod = startAndEndOfMonth({
        year: getYear(new Date(phaseStartDate)),
        monthIndex: getMonthIndex(new Date(phaseStartDate)),
        keepLocalTime: true,
    }).startDate;
    const oneYearPeriod = dateCalculator(startOfCurrentMonth, 'subtract', 1, 'year');
    const allOptionOlderOfYear = startDateOfAllPeriod < oneYearPeriod;
    const oneYearPeriods = splitIntoOneYearPeriods(startDateOfAllPeriod, endOfCurrentMonth);
    const filteredOneYearPeriods = oneYearPeriods.filter(period => period.endDate !== endOfCurrentMonth);

    store.dispatch('accounting/setOneYearPeriods', filteredOneYearPeriods);

    return {
        all: {
            startDate: allOptionOlderOfYear ? oneYearPeriod : startDateOfAllPeriod,
            endDate: endOfCurrentMonth,
        },
        currentMonth: {
            startDate: startOfCurrentMonth,
            endDate: endOfCurrentMonth,
        },
        lastMonth: {
            startDate: dateCalculator(startOfCurrentMonth, 'subtract', 1, 'month'),
            endDate: endOfCurrentMonth,
        },
        lastSixMonth: {
            startDate: dateCalculator(startOfCurrentMonth, 'subtract', 6, 'months'),
            endDate: endOfCurrentMonth,
        },
        lastYear: {
            startDate: oneYearPeriod,
            endDate: endOfCurrentMonth,
        },
    }[periodKey];
};
