export default {
    data() {
        return {
            parties: [],
        };
    },

    computed: {
        formattedPeriods() {
            return this.totals?.map((total, i) => {
                const items = this.addPayer(this.periods[total?.period]);

                return {
                    ...total,
                    items,
                    active: i === 0,
                };
            });
        },
    },

    methods: {
        addPayer(transactions) {
            return transactions?.map(item => {
                const party = this.parties?.find(({ partyId }) => partyId === item.payerId);
                return {
                    ...item,
                    payer: party?.fullName || null,
                };
            });
        },

        async getOccupancy() {
            try {
                const occupancy = await this.$leasingServiceDataProvider.getOne('occupancy', {
                    occupancy_id: this.currentProfile?.occupancyId,
                });
                this.parties = occupancy.parties?.map(({ partyId, fullName }) => ({
                    partyId,
                    fullName,
                }));
            } catch (error) {
                this.notifyError(error.message);
            }
        },
    },
};
